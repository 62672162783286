<template>
  <div>
    <Title title="Videos" />
    <VideoList :videoListItems="videoListItems" />
  </div>
</template>

<script>
import Title from '@/components/Title.vue'
import VideoList from '@/components/VideoList.vue'

export default {
  components: {
    Title,
    VideoList
  },
  data () {
    return {
      videoListItems: [
        { videoLink: 'https://www.youtube.com/watch?v=c8KZjs31LmU', videoEmbed: 'https://www.youtube.com/embed/c8KZjs31LmU', videoName: 'Maintenance Release' },
        { videoLink: 'https://www.youtube.com/watch?v=vVzLe_AKLXM', videoEmbed: 'https://www.youtube.com/embed/vVzLe_AKLXM', videoName: 'Eurofox 3K Daily Inspection Part 1' },
        { videoLink: 'https://www.youtube.com/watch?v=5EjFV0Gq5WY', videoEmbed: 'https://www.youtube.com/embed/5EjFV0Gq5WY', videoName: 'Eurofox 3K Daily Inspection Part 2' },
        { videoLink: 'https://www.youtube.com/watch?v=tMT-bYU-CEM', videoEmbed: 'https://www.youtube.com/embed/tMT-bYU-CEM', videoName: 'Eurofox 3K Engine' }
      ]
    }
  }
}
</script>
