<template>
  <div>
    <ul class="video-list">
      <li class="list-item" v-for="(item, index) in videoListItems" :key="index">
        <a :href="item.videoLink">
          <iframe class="video-player" :src="item.videoEmbed" frameborder="0"></iframe>
          <span class="video-text">{{ item.videoName }}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['videoListItems']
}
</script>

<style lang="scss" scoped>
@use '@/styles/_presets.scss';
@import '@/styles/_colors.scss';

.video-list {
  width: 80vw;
  margin: auto;
  padding: 0;
}

.list-item {
  list-style-type: none;
  border: 1px solid $primary;
  break-inside: avoid-column;
  margin-left: -1px;
  margin-top: -1px;
  transition: background-color 0.3s ease-in-out;
  background-color: white;

  &:hover {
    background-color: $secondary;
  }

  a {
    @extend h2;
    display: flex;
    @media screen and (max-width: 600px) { flex-direction: column; justify-content: center; }
    justify-content: space-between;
    align-items: center;
    padding: 1vh;
    text-decoration: none;
    flex-wrap: wrap;
  }
}

.video-player {
  max-width: 75vw;
}
</style>
